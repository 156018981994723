import { Store } from '@ngxs/store';
import {
  MenuItemModel,
  MenuPageModel,
  MenuService,
  MenuState,
  PeriodContextDisplayComponent,
  WarningDisplayComponent,
} from '@roadrecord/app-layout/common';
import {
  BASIC_DATA_PAGE_PATH,
  CHARGING_STATION_ROUTE_PATH,
  DAILY_PERMANENT_DESTINATION_PATH,
  END_MONTH_STATUS_ROUTE_PATH,
  EXPENSE_ROUTH_PATH,
  FINALIZATION_OF_ROUTE_IMPORT_ROUTE_PATH,
  FINALIZATION_OF_ROUTE_ROUTE_PATH,
  FUEL_PRICE_ROUTE_PATH,
  FUELING_ROUTE_PATH,
  HEAD_OFFICE_OR_SITE_PATH,
  MONTH_ACTIVITIES_PAGE_PATH,
  PARTNER_DISTANCE_ROUTE_PATH,
  PARTNER_IMPORT_FULL_ROUTE_PATH,
  PARTNER_IMPORT_ROUTE_PATH,
  PARTNER_ROUTE_PATH,
  REPORT_PRINT_PATH,
  REPORT_SITE_PATH,
  SPECIAL_DAYS_ROUTE_PATH,
  START_MONTH_STATUS_ROUTE_PATH,
  TRIP_REASON_ROUTE_PATH,
  VEHICLE_ROUTE_PATH,
  ViewportService,
} from '@roadrecord/common/common';
import { FirstStepsState } from '@roadrecord/first-steps/common';
import {
  isCompanyVehicle,
  isPrivateVehicleAndIsNotSelfEmployed,
  isPrivateVehicleAndIsSelfEmployed,
} from '@roadrecord/preferences-state/common';
import { isNil } from '@roadrecord/type-guard';
import { AbstractPeriodContextModel, PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';

export const menuConfig: MenuPageModel[] = [
  new MenuPageModel({
    uniqId: 'BASIC_DATA_MENU',
    label: 'APP_LAYOUT.MENU.PAGE.BASIC_DATA',
    route: BASIC_DATA_PAGE_PATH,
    contextCallback: () => ({
      callback: (viewportService: ViewportService, store: Store) => {
        if (viewportService.isMobile) {
          return false;
        }
        const stateMenuDisabledMenuPageGroup = store.selectSnapshot(MenuState.disabled_menu_page_group);
        return (
          stateMenuDisabledMenuPageGroup === undefined ||
          !Array.isArray(stateMenuDisabledMenuPageGroup) ||
          stateMenuDisabledMenuPageGroup.length === 0
        );
      },
      deps: [ViewportService, Store],
    }),
    icon: 'clipboard-text',
    items: [
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.HEAD_OFFICE_OR_SITE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.HEAD_OFFICE_OR_SITE.HELP',
        route: HEAD_OFFICE_OR_SITE_PATH,
        icon: 'home-map-marker',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER.HELP',
        route: PARTNER_ROUTE_PATH,
        icon: 'account-group',
        saveSubRoutes: [PARTNER_IMPORT_ROUTE_PATH],
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.CHARGING_STATION.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.CHARGING_STATION.HELP',
        route: CHARGING_STATION_ROUTE_PATH,
        icon: 'gas-station',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.VEHICLE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.VEHICLE.HELP',
        route: VEHICLE_ROUTE_PATH,
        icon: 'car',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.TRIP_REASON.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.TRIP_REASON.HELP',
        route: TRIP_REASON_ROUTE_PATH,
        icon: 'book-open-page-variant',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_IMPORT.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_IMPORT.HELP',
        route: PARTNER_IMPORT_FULL_ROUTE_PATH,
        icon: 'cloud-upload',
      }),
      new MenuItemModel({
        uniqId: 'VEHICLE_PARTNER_JOIN',
        label: 'APP_LAYOUT.MENU.ITEM.VEHICLE_PARTNER_JOIN.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.VEHICLE_PARTNER_JOIN.HELP',
        route: 'vehicle-partner-join',
        icon: 'set-center-right',
      }),
      new MenuItemModel({
        uniqId: 'PARTNER_VEHICLE_JOIN',
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_VEHICLE_JOIN.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_VEHICLE_JOIN.HELP',
        route: 'partner-vehicle-join',
        icon: 'set-left-center',
      }),
      new MenuItemModel({
        uniqId: 'DAILY_PERMANENT_DESTINATION',
        label: 'APP_LAYOUT.MENU.ITEM.DAILY_PERMANENT_DESTINATION.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.DAILY_PERMANENT_DESTINATION.HELP',
        route: DAILY_PERMANENT_DESTINATION_PATH,
        icon: 'repeat',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_DISTANCE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_DISTANCE.HELP',
        route: PARTNER_DISTANCE_ROUTE_PATH,
        icon: 'map-marker-distance',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.FUEL_PRICE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.FUEL_PRICE.HELP',
        route: FUEL_PRICE_ROUTE_PATH,
        icon: 'tag-text-outline',
      }),
    ],
  }),
  new MenuPageModel({
    uniqId: 'BASIC_DATA_MENU_WITH_WARNING_DISPLAY',
    label: 'APP_LAYOUT.MENU.PAGE.BASIC_DATA',
    route: BASIC_DATA_PAGE_PATH,
    contextCallback: () => ({
      callback: (viewportService: ViewportService, store: Store) => {
        if (viewportService.isMobile) {
          return false;
        }
        const stateMenuDisabledMenuPageGroup = store.selectSnapshot(MenuState.disabled_menu_page_group);
        return (
          stateMenuDisabledMenuPageGroup !== undefined &&
          Array.isArray(stateMenuDisabledMenuPageGroup) &&
          stateMenuDisabledMenuPageGroup.length > 0
        );
      },
      deps: [ViewportService, Store],
    }),
    icon: 'clipboard-text',
    customAreaComponent: WarningDisplayComponent,
    items: [
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.HEAD_OFFICE_OR_SITE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.HEAD_OFFICE_OR_SITE.HELP',
        route: HEAD_OFFICE_OR_SITE_PATH,
        icon: 'home-map-marker',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER.TITLE',
        route: PARTNER_ROUTE_PATH,
        icon: 'account-group',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.CHARGING_STATION.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.CHARGING_STATION.HELP',
        route: CHARGING_STATION_ROUTE_PATH,
        icon: 'gas-station',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.VEHICLE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.VEHICLE.HELP',
        route: VEHICLE_ROUTE_PATH,
        icon: 'car',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.TRIP_REASON.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.TRIP_REASON.HELP',
        route: TRIP_REASON_ROUTE_PATH,
        icon: 'book-open-page-variant',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_IMPORT.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_IMPORT.HELP',
        route: PARTNER_IMPORT_FULL_ROUTE_PATH,
        icon: 'cloud-upload',
      }),
      new MenuItemModel({
        uniqId: 'VEHICLE_PARTNER_JOIN',
        label: 'APP_LAYOUT.MENU.ITEM.VEHICLE_PARTNER_JOIN.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.VEHICLE_PARTNER_JOIN.HELP',
        route: 'vehicle-partner-join',
        icon: 'set-center-right',
      }),
      new MenuItemModel({
        uniqId: 'PARTNER_VEHICLE_JOIN',
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_VEHICLE_JOIN.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_VEHICLE_JOIN.HELP',
        route: 'partner-vehicle-join',
        icon: 'set-left-center',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_DISTANCE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_DISTANCE.HELP',
        route: PARTNER_DISTANCE_ROUTE_PATH,
        icon: 'map-marker-distance',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.FUEL_PRICE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.FUEL_PRICE.HELP',
        route: FUEL_PRICE_ROUTE_PATH,
        icon: 'tag-text-outline',
      }),
    ],
  }),
  new MenuPageModel({
    uniqId: 'BASIC_DATA_MENU_IN_MOBILE',
    label: 'APP_LAYOUT.MENU.PAGE.BASIC_DATA',
    route: BASIC_DATA_PAGE_PATH,
    contextCallback: () => ({
      callback: (viewportService: ViewportService) => {
        if (viewportService.isMobile) {
          return true;
        }
      },
      deps: [ViewportService],
    }),
    icon: 'clipboard-text',
    customAreaComponent: WarningDisplayComponent,
    items: [
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.HEAD_OFFICE_OR_SITE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.HEAD_OFFICE_OR_SITE.HELP',
        route: HEAD_OFFICE_OR_SITE_PATH,
        icon: 'home-map-marker',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER.HELP',
        route: PARTNER_ROUTE_PATH,
        icon: 'account-group',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.CHARGING_STATION.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.CHARGING_STATION.HELP',
        route: CHARGING_STATION_ROUTE_PATH,
        icon: 'gas-station',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.VEHICLE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.VEHICLE.HELP',
        route: VEHICLE_ROUTE_PATH,
        icon: 'car',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.TRIP_REASON.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.TRIP_REASON.HELP',
        route: TRIP_REASON_ROUTE_PATH,
        icon: 'book-open-page-variant',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_IMPORT.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_IMPORT.HELP',
        route: PARTNER_IMPORT_FULL_ROUTE_PATH,
        disabled: true,
        icon: 'cloud-upload',
      }),
      new MenuItemModel({
        uniqId: 'VEHICLE_PARTNER_JOIN',
        label: 'APP_LAYOUT.MENU.ITEM.VEHICLE_PARTNER_JOIN.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.VEHICLE_PARTNER_JOIN.HELP',
        route: 'vehicle-partner-join',
        icon: 'set-center-right',
      }),
      new MenuItemModel({
        uniqId: 'PARTNER_VEHICLE_JOIN',
        label: 'APP_LAYOUT.MENU.ITEM.PARTNER_VEHICLE_JOIN.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.PARTNER_VEHICLE_JOIN.HELP',
        route: 'partner-vehicle-join',
        icon: 'set-left-center',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.FUEL_PRICE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.FUEL_PRICE.HELP',
        route: FUEL_PRICE_ROUTE_PATH,
        icon: 'tag-text-outline',
      }),
    ],
  }),
  new MenuPageModel({
    // Magan auto es nem egyeni vallalkozo
    uniqId: 'MONTH_ACTIVITIES_MENU_PRIVATE_IS_NOT_SELF_EMPLOYED',
    groupUniqId: 'MONTH_ACTIVITIES',
    label: 'APP_LAYOUT.MENU.PAGE.MONTH_ACTIVITIES',
    route: MONTH_ACTIVITIES_PAGE_PATH,
    contextCallback: () => ({
      callback: (
        viewportService: ViewportService,
        store: Store,
        periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>
      ) => {
        const periodContext = store.selectSnapshot<AbstractPeriodContextModel<any>>(periodContextStateSelectorsService.context);
        const finishedFirstStep = store.selectSnapshot(FirstStepsState.finished);
        if (viewportService.isMobile && (periodContext === undefined || finishedFirstStep === false)) {
          return true;
        }
        return isPrivateVehicleAndIsNotSelfEmployed(periodContext);
      },
      deps: [ViewportService, Store, PeriodContextStateSelectorsService],
    }),
    icon: 'highway',
    customAreaComponent: PeriodContextDisplayComponent,
    defaultRoute: FINALIZATION_OF_ROUTE_ROUTE_PATH,
    items: [
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.WORK_AND_HOLIDAYS.TITLE',
        route: SPECIAL_DAYS_ROUTE_PATH,
        icon: 'calendar',
      }),
      new MenuItemModel({
        label: '',
        route: FINALIZATION_OF_ROUTE_IMPORT_ROUTE_PATH,
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        label: '',
        route: 'recommendation/process',
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.FINALIZATION_OF_ROUTE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.FINALIZATION_OF_ROUTE.HELP',
        route: FINALIZATION_OF_ROUTE_ROUTE_PATH,
        icon: 'map-marker-multiple',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.REPORT_PRINTING.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.REPORT_PRINTING.HELP',
        route: REPORT_PRINT_PATH,
        icon: 'printer',
      }),
    ],
  }),
  new MenuPageModel({
    // Magan auto es egyeni vallalkozo
    uniqId: 'MONTH_ACTIVITIES_MENU_PRIVATE_IS_SELF_EMPLOYED',
    groupUniqId: 'MONTH_ACTIVITIES',
    label: 'APP_LAYOUT.MENU.PAGE.MONTH_ACTIVITIES',
    route: MONTH_ACTIVITIES_PAGE_PATH,
    contextCallback: () => ({
      callback: (store, periodContextStateSelectorsService) =>
        isPrivateVehicleAndIsSelfEmployed(store.selectSnapshot(periodContextStateSelectorsService.context)),
      deps: [Store, PeriodContextStateSelectorsService],
    }),
    icon: 'highway',
    customAreaComponent: PeriodContextDisplayComponent,
    defaultRoute: FINALIZATION_OF_ROUTE_ROUTE_PATH,
    items: [
      new MenuItemModel({
        uniqId: 'MILAGE_OF_PREVIOUS_MONTH',
        label: 'APP_LAYOUT.MENU.ITEM.MILAGE_OF_PREVIOUS_MONTH.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.MILAGE_OF_PREVIOUS_MONTH.HELP',
        route: START_MONTH_STATUS_ROUTE_PATH,
        icon: 'clock-start',
      }),
      new MenuItemModel({
        label: '',
        route: 'mileages/start',
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.WORK_AND_HOLIDAYS.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.WORK_AND_HOLIDAYS.HELP',
        route: SPECIAL_DAYS_ROUTE_PATH,
        icon: 'calendar',
      }),
      new MenuItemModel({
        label: '',
        route: FINALIZATION_OF_ROUTE_IMPORT_ROUTE_PATH,
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        uniqId: 'END_MONTH_STATUS',
        label: 'APP_LAYOUT.MENU.ITEM.MILEAGE_OF_ACTUAL_MONTH.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.MILEAGE_OF_ACTUAL_MONTH.HELP',
        route: END_MONTH_STATUS_ROUTE_PATH,
        icon: 'clock-end',
      }),
      new MenuItemModel({
        label: '',
        route: 'mileages/end',
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        label: '',
        route: 'recommendation/process',
        icon: 'tune',
        hidden: true,
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.FINALIZATION_OF_ROUTE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.FINALIZATION_OF_ROUTE.HELP',
        route: FINALIZATION_OF_ROUTE_ROUTE_PATH,
        icon: 'map-marker-multiple',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.REPORT_PRINTING.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.REPORT_PRINTING.HELP',
        route: REPORT_PRINT_PATH,
        icon: 'printer',
      }),
    ],
  }),
  new MenuPageModel({
    // Ceges auto
    uniqId: 'MONTH_ACTIVITIES_MENU_COMPANY',
    groupUniqId: 'MONTH_ACTIVITIES',
    label: 'APP_LAYOUT.MENU.PAGE.MONTH_ACTIVITIES',
    route: MONTH_ACTIVITIES_PAGE_PATH,
    contextCallback: () => ({
      callback: (store, periodContextStateSelectorsService) =>
        isCompanyVehicle(store.selectSnapshot(periodContextStateSelectorsService.context)),
      deps: [Store, PeriodContextStateSelectorsService],
    }),
    icon: 'highway',
    customAreaComponent: PeriodContextDisplayComponent,
    defaultRoute: FINALIZATION_OF_ROUTE_ROUTE_PATH,
    items: [
      new MenuItemModel({
        uniqId: 'MILAGE_OF_PREVIOUS_MONTH',
        label: 'APP_LAYOUT.MENU.ITEM.MILAGE_OF_PREVIOUS_MONTH.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.MILAGE_OF_PREVIOUS_MONTH.HELP',
        route: START_MONTH_STATUS_ROUTE_PATH,
        icon: 'clock-start',
      }),
      new MenuItemModel({
        label: '',
        route: 'mileages/start',
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.WORK_AND_HOLIDAYS.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.WORK_AND_HOLIDAYS.HELP',
        route: SPECIAL_DAYS_ROUTE_PATH,
        icon: 'calendar',
      }),
      new MenuItemModel({
        uniqId: 'FUELING',
        label: 'APP_LAYOUT.MENU.ITEM.FUELING.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.FUELING.HELP',
        route: FUELING_ROUTE_PATH,
        icon: 'gas-station',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.EXPENSE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.EXPENSE.HELP',
        route: EXPENSE_ROUTH_PATH,
        icon: 'cash',
      }),
      new MenuItemModel({
        label: '',
        route: FINALIZATION_OF_ROUTE_IMPORT_ROUTE_PATH,
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        uniqId: 'END_MONTH_STATUS',
        label: 'APP_LAYOUT.MENU.ITEM.MILEAGE_OF_ACTUAL_MONTH.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.MILEAGE_OF_ACTUAL_MONTH.HELP',
        route: END_MONTH_STATUS_ROUTE_PATH,
        icon: 'clock-end',
      }),
      new MenuItemModel({
        label: '',
        route: 'mileages/end',
        icon: '',
        hidden: true,
      }),
      new MenuItemModel({
        label: '',
        route: 'recommendation/process',
        icon: 'tune',
        hidden: true,
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.FINALIZATION_OF_ROUTE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.FINALIZATION_OF_ROUTE.HELP',
        route: FINALIZATION_OF_ROUTE_ROUTE_PATH,
        icon: 'map-marker-multiple',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.REPORT_PRINTING.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.REPORT_PRINTING.HELP',
        route: REPORT_PRINT_PATH,
        icon: 'printer',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.REPORT_SITE.TITLE',
        helpTooltip: 'APP_LAYOUT.MENU.ITEM.REPORT_SITE.HELP',
        route: REPORT_SITE_PATH,
        icon: 'chart-line',
      }),
    ],
  }),
  new MenuPageModel({
    // Kamu ha nincs period context csak tab ful legyen
    uniqId: 'MONTH_ACTIVITIES_MENU_COMPANY_NOT_FOUND_PERIOD_CONTEXT_PLACEHOLDER',
    groupUniqId: 'MONTH_ACTIVITIES',
    label: 'APP_LAYOUT.MENU.PAGE.MONTH_ACTIVITIES',
    route: MONTH_ACTIVITIES_PAGE_PATH,
    contextCallback: () => ({
      callback: (store: Store) => {
        const periodContext = store.selectSnapshot(states => states.periodContext);
        return isNil(periodContext) || Object.keys(periodContext).length === 0;
      },
      deps: [Store],
    }),
    icon: 'highway',
    items: [],
  }),
  new MenuPageModel({
    uniqId: 'USER_PROFILE_MENU',
    label: 'APP_LAYOUT.MENU.PAGE.MY_PROFILE',
    route: '',
    customBackButtonLabel: 'APP_LAYOUT.NAV_TOOLBAR.BACK_TO_MENU',
    customBackButtonLabelCallback: (menuService: MenuService) => menuService.restoreLastState(),
    visible: false,
    icon: 'account',
    hidden: true,
    items: [
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.APPLICATION_SETTINGS',
        route: 'user/application-settings',
        icon: 'cog',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.COMPANY_MEMBER',
        route: 'user/company/member',
        icon: 'account-network',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.COMPANY_DATA',
        route: 'user/subscription-data',
        icon: 'badge-account-horizontal',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.CHANGE_PASSWORD',
        route: 'user/change-password',
        icon: 'key-change',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.TERMS_AND_CONDITIONS',
        icon: 'book-open-page-variant',
        outsideLink: 'https://www.roadrecord.hu/altalanos-szerzodesi-feltetelek/',
      }),
      new MenuItemModel({
        label: 'APP_LAYOUT.MENU.ITEM.PRIVACY_POLICY',
        icon: 'shield-account',
        outsideLink: 'https://www.roadrecord.hu/felhasznalasi-feltetelek-adatvedelmi-nyilatkozat/',
      }),
    ],
  }),
];
